@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
:root {
  --primary: #2b2f77;
}
.activeButton {
  color: red;
}
body {
  font-family: "Montserrat", sans-serif;
}
@layer components {
  .btn-primary {
    @apply p-2.5 px-6 bg-red rounded-lg text-white font-bold text-xs uppercase;

    /* py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75; */
  }
  .btn-secondary {
    @apply p-2.5 px-6 bg-grey rounded-lg text-black font-bold text-xs uppercase;
  }
  .btn-blue {
    @apply p-1.5 px-6 bg-blue rounded-md text-white font-bold text-xs uppercase;
  }
}
#__next {
  height: 100%;
}
dialog::backdrop {
  background-color: #000;
  opacity: 0.4;
}

dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

.rdp-tfoot {
  color: #ae1512;
}
